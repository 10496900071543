import makeStyles from '@material-ui/core/styles/makeStyles'
import { createStyles, Theme } from '@material-ui/core'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    div: {
      marginTop: theme.spacing(3),
    },
    root: {
      marginBottom: theme.spacing(2),
    },
    category: {
      width: theme.spacing(40),
      marginBottom: theme.spacing(2),
    },
    code: {
      width: theme.spacing(40),
      marginBottom: theme.spacing(2),
    },
    sellBuyType: {
      width: theme.spacing(20),
      marginBottom: theme.spacing(2),
    },
    price: {
      width: theme.spacing(20),
      marginBottom: theme.spacing(2),
    },
    publishButton: {
      marginTop: theme.spacing(3),
      // top: theme.spacing(5),
      // left: theme.spacing(75),
      width: theme.spacing(40),
      height: theme.spacing(8),
      // backgroundColor: '#f77b18',
    },
  })
)
