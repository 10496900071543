import React from 'react'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import CreateStrategyEditor from 'components/ContentAdmin/StrategyTab/CreateStrategyEditor'
import CreateSignalEditor from '../../components/ContentAdmin/SignalTab/CreateSignalEditor'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    button: {
      marginTop: theme.spacing(3),
    },
    opinion: {
      width: theme.spacing(28),
    },
  })
)

export default () => {
  const classes = useStyles()

  return (
    <Layout>
      <SEO title="Home" />
      <div className={classes.root}>
        <CreateSignalEditor />
      </div>
    </Layout>
  )
}
