import React, { useEffect } from 'react'
import 'react-quill/dist/quill.snow.css'
import { Button, InputLabel, TextField } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { navigate } from 'gatsby'
import { useStyles } from './style'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { useTranslation } from 'react-i18next'
import {
  OpenPositionAction,
  OpenPositionState,
  SignalAction,
  StrategyAction,
  StrategyState,
} from '../../../Strategy/state'
import { CONST, CONTENT_ADMIN_ROUTES, ROUTES, SELL_BUY_TYPE, STRATEGY_TYPE_ENUM } from '../../../../constant'
import { useState } from '@hookstate/core'
import { getObj } from '../../../../utils/hookState'

export default function CreateSignalEditor() {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const [code, setCode] = React.useState('VN30F2105')
  const strategyListState = useState(StrategyState.list)
  const strategyList = getObj(strategyListState.data)
  const [strategyId, setStrategyId] = React.useState(strategyList.length > 0 ? strategyList[0].id : 0)
  const [sellBuyType, setSellBuyType] = React.useState(SELL_BUY_TYPE.BUY)

  const strategyOneState = useState(StrategyState.one)
  const openPositionState = useState(OpenPositionState.list)

  const [codeToSelect, setCodeToSelect] = React.useState(false)

  useEffect(() => {
    StrategyAction.findAndCountEquity({ status: 'ACTIVE' }, false, 0, Number.MAX_SAFE_INTEGER)()
  }, [])

  useEffect(() => {
    StrategyAction.findOne(strategyId)().then(() => {
      if (getObj(strategyOneState).type === CONST.STRATEGY_TYPE.DERIVATIVES) {
        setCodeToSelect(false)
        setCode('VN30F2105')
      } else {
        setCode('')
        if (sellBuyType === 'SELL') {
          OpenPositionAction.find(strategyId)().then(() => setCode(getObj(openPositionState.data)[0].code))
          setCodeToSelect(true)
        } else {
          setCodeToSelect(false)
        }
      }
    })
  }, [sellBuyType, strategyId])

  const handleClickPublish = () => {
    SignalAction.create(strategyId, code, sellBuyType)()
    navigate(`${ROUTES.CONTENT_ADMIN}${CONTENT_ADMIN_ROUTES.SIGNAL}`)
  }

  return (
    <Grid className={classes.div} container spacing={3}>
      <Grid item xs={12} md={9}>
        <FormControl variant="outlined">
          <InputLabel>Strategy</InputLabel>
          <Select
            className={classes.category}
            value={strategyId}
            onChange={(e) => setStrategyId(e.target.value as number)}
            label="Strategy"
          >
            {strategyList.map((record) => (
              <MenuItem value={record.id}>{record.name}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <div>
          <FormControl variant="outlined">
            <InputLabel>Sell Buy Type</InputLabel>
            <Select
              className={classes.category}
              value={sellBuyType}
              onChange={(e) => setSellBuyType(e.target.value as string)}
              label="Sell Buy Type"
            >
              {Object.values(SELL_BUY_TYPE).map((record) => (
                <MenuItem value={record}>{record}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {codeToSelect ? (
          <FormControl variant="outlined">
            <InputLabel>Code</InputLabel>
            <Select
              className={classes.category}
              value={code}
              onChange={(e) => setCode(`${e.target.value}`.toUpperCase())}
              label="Code"
            >
              {getObj(openPositionState.data).map((record) => (
                <MenuItem value={record.code}>{record.code}</MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <TextField
            className={classes.code}
            fullWidth
            label={'Code'}
            value={code}
            onChange={(e) => setCode(e.target.value.toUpperCase())}
            variant="outlined"
          />
        )}

        {/*<TextField*/}
        {/*  className={classes.code}*/}
        {/*  fullWidth*/}
        {/*  label={'Price'}*/}
        {/*  type="number"*/}
        {/*  value={price}*/}
        {/*  onChange={(e) => setPrice((e.target.value as unknown) as number)}*/}
        {/*  variant="outlined"*/}
        {/*/>*/}
        <div>
          <Button className={classes.publishButton} variant="contained" color="primary" onClick={handleClickPublish}>
            Đăng bài
          </Button>
        </div>
      </Grid>
    </Grid>
  )
}
